window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    require('bootstrap')
} catch (e) {
    console.log(e);
}
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
require('daterangepicker');
const Swal = require('sweetalert2')
const L = require('leaflet');

let wasSet = 1;
$(function(){
    $('.datepicker').daterangepicker({
        startDate: '01.09.2022',
        endDate: '04.09.2022',
        minDate: '01.09.2022',
        maxDate: '04.09.2022',
        isCustomDate: function(date) {
            if(wasSet>2){
                return false;
            }
            if(date.format('DD.MM.YYYY') === "03.09.2022"){
                if(wasSet === 1) {
                    wasSet++;
                }else{
                    return "bg-success";
                }
            }
            return false;
        },
        locale: {
            format: 'DD.MM.YYYY',
            "applyLabel": "Aplica",
            "cancelLabel": "Anuleaza",
            "fromLabel": "De la",
            "toLabel": "Pana la",
            "customRangeLabel": "Format",
            "weekLabel": "S",
            "daysOfWeek": [
                "Du",
                "Lu",
                "Ma",
                "Mi",
                "Jo",
                "Vi",
                "Sa"
            ],
            "monthNames": [
                "Ianuarie",
                "Februarie",
                "Martie",
                "Aprilie",
                "Mai",
                "Iunie",
                "Iulie",
                "August",
                "Septembrie",
                "Octombrie",
                "Noiembrie",
                "Decembrie"
            ]
        }
    }).on('apply.daterangepicker', function(ev, picker) {
        $(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
    }).on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
    });
    $('#logout').click(function(e){
        e.preventDefault();
        $("#logout-form").submit();
        return false;
    });
    let $dataSwal = $("[data-swal]")
    /** auth form **/
    let $authUser = $("input[name='username']");
    let $authPassword = $("#auth-password");
    let $authSubmit = $("#auth-submit");
    if($authPassword.length){
        //was checked
        let authWasChecked = false;
        //check if username has password
        let checkUsername = function(item){
            let val = item.val();
            console.log(val);
            if(!authWasChecked && !val.length){
                return false;
            }
            $.post($authPassword.data('url'),{username: val}, function(data){
                if(data === "1"){
                    $authPassword.removeClass('d-none');
                }else{
                    $authPassword.addClass('d-none');
                }
                authWasChecked = true;
                $authSubmit.removeClass('d-none');
            });
            return true;
        };
        //on load
        setTimeout(function(){
            console.log('timeout');
            console.log($("input[name='username']").val());
            checkUsername($authUser);
        },10);
        //debounce username & check if has password
        $authUser.on('input', debounce(function(){
            return checkUsername($(this));
        }, 200));
        $('#auth-form').submit(function(){
            if(!authWasChecked){
                return false;
            }
            return true;
        });
    }
    /** rsvp form **/
    if($dataSwal.length){
        let html = $dataSwal.removeClass('d-none');
        $dataSwal.remove();
        Swal.fire({
            html: html.prop('outerHTML'),
            icon: html.data('swal'),
            title: html.data('swal-title'),
            showCloseButton: true,
            showCancelButton: false,
        });
    }
    let $submit = $("#rsvp-submit")
    if($('#rsvp').length) {
        //handle radio
        let handleRadio = function (radio) {
            let target = $(radio).data('cb-radio');
            let showValue = $(radio).data('cb-radio-value') ? parseInt($(radio).data('cb-radio-value')).toString() : "1";
            if (target === "#rsvp-info") {
                if ($(radio).val() === "0") {
                    $submit.removeClass('d-none');
                } else {
                    $submit.addClass('d-none');
                }
            }
            if ($(radio).val() === showValue) {
                $(target).show();
            } else {
                $(target).hide();
            }
        }
        //radio on change
        $('[data-cb-radio]').change(function () {
            $('input[name="' + $(this).attr("name") + '"]').removeClass('is-invalid');
            $(this).parent().find('.invalid-feedback').remove()
            handleRadio(this);
        });
        $('input[type="text"],textarea').change(function () {
            $(this).removeClass('is-invalid');
            $(this).parent().find('.invalid-feedback').remove()
        });
        //radio on load
        $('[data-cb-radio]:checked').each(function (key, item) {
            handleRadio(item);
        });
        //scroll to RSVP
        let $scrollToRSVP = $("#scroll-to-rsvp");
        if($(window).width() < 600) {
            let checkRSVPScroll = function(){
                if ($("#rsvp").isInViewport()) {
                    $('#scroll-to-rsvp').addClass('d-none').hide();
                } else {
                    $('#scroll-to-rsvp').removeClass('d-none').show();
                }
            };
            checkRSVPScroll();
            $(window).on('scroll',function(){
                checkRSVPScroll();
            })
            $(window).on('resize', function(){
                checkRSVPScroll();
            });
        }
    }
    if($("#map").length){
        let map = L.map('map').setView([39.99362, 23.60104], 11);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

        let hotelIcon = L.icon({
            iconUrl:       '/images/map/marker-hotel.png',
            shadowUrl:     '/images/map/marker-shadow.png',
            iconSize:    [32, 32],
            iconAnchor:  [16, 32],
            popupAnchor: [1, -28],
            tooltipAnchor: [16, -28],
            shadowSize:  [32, 32]
        });

        let churchIcon = L.icon({
            iconUrl:       '/images/map/marker-church.png',
            shadowUrl:     '/images/map/marker-shadow.png',
            iconSize:    [32, 32],
            iconAnchor:  [16, 32],
            popupAnchor: [1, -28],
            tooltipAnchor: [16, -28],
            shadowSize:  [32, 32]
        });

        L.marker([39.92903, 23.58397], {icon: churchIcon}).addTo(map)
            .bindPopup('Biserica Sf. Nicolas<br /><a href="https://goo.gl/maps/H9tWqkMLnwHnbscY9" target="_blank">Deschide in Google Maps</a>');

        L.marker([39.99362, 23.60104], {icon: hotelIcon}).addTo(map)
            .bindPopup('Blue Carpet Luxury Suites<br /><a href="https://g.page/BlueCarpetSuites" target="_blank">Deschide in Google Maps</a>');

        L.marker([39.99275, 23.60048], {icon: hotelIcon}).addTo(map)
            .bindPopup('Cocooning Suites by Blue Carpet<br /><a href="https://g.page/cocooningsuites" target="_blank">Deschide in Google Maps</a>');

        $('button[data-bs-target="#nav-map"]').on('shown.bs.tab', function (e) {
            map.invalidateSize();
            setTimeout(function () {
                window.dispatchEvent(new Event('resize'));
            }, 1000);
        });
    }
});
function debounce(func, wait, immediate) {
    let timeout;
    return function() {
        let context = this, args = arguments;
        let later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}
$.fn.isInViewport = function() {
    let elementTop = $(this).offset().top;
    let elementBottom = elementTop + $(this).outerHeight();

    let viewportTop = $(window).scrollTop();
    let viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};
